import React, { useCallback, useMemo } from "react";
import { ListSubheader, MenuItem, TextField } from "@mui/material";
import { match } from "ts-pattern";

export type SelectOption =
  | {
      kind: "value";
      key: string;
      value: string;
      label: React.ReactNode;
    }
  | { kind: "section"; key: string; label: string };

type Props = {
  options: SelectOption[];
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  label?: string;
  error?: boolean;
  minWidth?: number;
  required?: boolean;
  disabled?: boolean;
};

/**
 * The basic select component.
 */
export const Select: React.FC<Props> = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  error,
  minWidth,
  required,
  disabled,
}) => {
  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(evt.target.value);
    },
    [onChange],
  );

  const renderOptions = useMemo(
    () =>
      options.map((item) =>
        match(item)
          .with({ kind: "value" }, ({ key, value, label }) => (
            <MenuItem key={key} value={value}>
              {label}
            </MenuItem>
          ))
          .with({ kind: "section" }, ({ key, label }) => (
            <ListSubheader key={key}>{label}</ListSubheader>
          ))
          .exhaustive(),
      ),
    [options],
  );

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      label={label}
      error={error}
      sx={{ minWidth }}
      required={required}
      disabled={disabled}
      fullWidth
      select
    >
      {renderOptions}
    </TextField>
  );
};
